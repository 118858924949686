<template>
  <v-dialog
    :value="true"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card tile class="grey darken-4">
      <v-card-title class="primary text-subtitle-1 text-md-h6 text-lg-h5 white--text font-weight-normal elevation-12 justify-start">
          <v-img
            src="/img/cc-white-transparent-logo.png"
            max-width="140px"
            contain
          />
        <v-divider vertical class="primary lighten-1 mx-4" />
        Preconstruction Request for Proposal
        <v-spacer />
        <v-btn
          icon
          color="white"
          @click="cancel"
        >
          <v-icon>fa fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-8">
          <v-form ref="form">
            <v-row justify="center">
              <v-col
                cols="12"
                md="6"
              >
                <v-card
                  flat
                  outlined
                  color="grey darken-4"
                >
                  <v-card-text class="pt-6">
                    <v-row>
                      <template v-for="(i, idx) in formFields">
                        <v-col
                          :key="idx"
                          cols="12"
                          :md="i.cols"
                          class="py-1"
                        >
                          <v-textarea
                            v-if="i.type === 'textarea'"
                            v-model="i.value"
                            background-color="grey lighten-3"
                            class="rounded elevation-0"
                            color="secondary"
                            dense
                            filled
                            :label="i.label"
                            :required="i.required"
                            :rules="i.rules ? i.rules : [true]"
                          />
                          <v-text-field
                            v-else
                            v-model="i.value"
                            background-color="grey lighten-3"
                            class="rounded elevation-0"
                            color="secondary"
                            dense
                            filled
                            :label="i.label"
                            :required="i.required"
                            :rules="i.rules ? i.rules : [true]"
                          />
                        </v-col>
                      </template>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="info"
                      :disabled="submitting"
                      elevation="0"
                      @click="submit"
                    >
                      {{ submitting ? 'Submitting...' : 'Submit' }}

                      <v-progress-circular
                        v-show="submitting"
                        indeterminate
                        size="22"
                        weight="3"
                      />
                      <v-icon
                        v-show="!submitting"
                        right
                      >
                        fa-paper-plane
                      </v-icon>
                    </v-btn>
                    <v-btn
                      text
                      color="grey"
                      @click="cancel"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/app'
import { fs } from '@/firebase'

export default {
  data () {
    return {
      submitting: false,
      formFields: [
        {
          value: '',
          label: 'Job Title',
          required: true,
          cols: '12',
          rules: this.required('Job Title')
        },
        {
          value: '',
          label: 'General Contractor',
          required: true,
          cols: '6',
          rules: this.required('General Contractor')
        },
        {
          value: '',
          label: 'Client',
          required: true,
          cols: '6',
          rules: this.required('Client')
        },
        {
          value: '',
          label: 'Location',
          required: true,
          cols: '12',
          rules: this.required('Location')
        },
        {
          value: '',
          label: 'Type of Work',
          required: true,
          type: 'textarea',
          cols: '12',
          rules: this.required('Type of Work')
        },
        {
          value: '',
          label: 'Bid Date',
          required: true,
          cols: '6',
          rules: this.dateRule()
        },
        {
          value: '',
          label: 'Sq. Ft',
          required: true,
          cols: '6',
          rules: this.required('Sq. Ft')
        },
        {
          value: '',
          label: 'Contact First Name',
          required: true,
          cols: '6',
          rules: this.required('Contact First Name')
        },
        {
          value: '',
          label: 'Contact Last Name',
          required: true,
          cols: '6',
          rules: this.required('Contact Last Name')
        },
        {
          value: '',
          label: 'Contact Email',
          required: true,
          cols: '12',
          rules: this.emailRule()
        }
      ]
    }
  },
  methods: {
    dateRule () {
      return [
        v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Date must be formatted YYYY-MM-DD'
      ]
    },
    required (fieldName) {
      return [
        v => (v !== null && v !== undefined && v !== '') || `${fieldName} is required`
      ]
    },
    emailRule () {
      return [
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ]
    },
    async submit () {
      this.submitting = true
      try {
        if (!this.$refs.form.validate()) {
          const invalidInput = this.$refs.form.inputs.find(i => !i.valid)
          this.$vuetify.goTo(invalidInput, { offset: 100 })
          this.submitting = false
          return
        }
        await fs.collection('preconstruction_rfp').add({
          to: 'infocc@ccindustrial.com',
          from: 'infocc@ccindustrial.com',
          replyTo: this.formFields[9].value,
          created_date: firebase.firestore.Timestamp.now(),
          template: {
            name: 'preconstruction_request_template',
            data: {
              job: this.formFields[0].value,
              gc: this.formFields[1].value,
              client: this.formFields[2].value,
              location: this.formFields[3].value,
              typeOfWork: this.formFields[4].value,
              bidDate: this.formFields[5].value,
              sqFt: this.formFields[6].value,
              firstName: this.formFields[7].value,
              lastName: this.formFields[8].value
            }
          }
        })
        this.$rolbar.info('Preconstruction form queued email for delivery!')
        this.formFields.forEach(i => {
          i.value = ''
        })
        this.submitting = false
        this.$emit('close-dialog')
      } catch (error) {
        this.$rollbar.configure({
          payload: {
            component: 'PreconstructionFormDialog.vue',
            formData: this.formFields
          }
        })
        this.$rollbar.error('Error submitting preconstruction form', error)
      }
    },
    cancel () {
      this.$emit('close-dialog')
    }
  }
}
</script>
